import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import authenticate from '@/auth/authenticate'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {

          path: '/app/sessions',
          component: () => import('@/views/app/sessions/Sessions'),

          children: [
            {
              path: 'sign-in',
              name: 'login',
              component: () => import('@/views/app/sessions/SignIn'),
            },
            {
              path: 'RememberPassword',
              name: 'RememberPassword',
              component: () => import('@/views/app/sessions/RememberPassword'),
            },
    ],
    },
        {
          path: '/',
          component: () => import('@/views/app/Index'),
          beforeEnter: authenticate,
          redirect: '/app/dashboard/statistics',

          children: [
            {
          path: '/app/dashboard',
          component: () => import('@/views/app/dashboard/Index'),

          children: [
            {
              path: 'statistics',
              name: 'statistics',
              meta: {
                breadcrumb: [{ name: 'Dashboard' }, { name: 'Statistics' }],
              },
              component: () => import('@/views/app/dashboard/Statistics'),
            },
          ],
        },
        {
          path: '/app/administration',
          component: () => import('@/views/app/administration/Index'),
          children: [
            {
              path: 'clientsConfig',
              name: 'clientsConfig',
              meta: {
                breadcrumb: [{ name: 'ClientsConfig' }, { name: 'clientsConfig' }],
              },
              component: () => import('@/views/app/administration/ClientsConfig'),
            },
            {
              path: 'emisorConfig',
              name: 'emisorConfig',
              meta: {
                breadcrumb: [{ name: 'EmisorConfig' }, { name: 'emisorConfig' }],
              },
              component: () => import('@/views/app/administration/EmisorConfig'),
            },
            {
              path: 'numerationsConfig',
              name: 'numerationsConfig',
              meta: {
                breadcrumb: [{ name: 'NumerationsConfig' }, { name: 'numerationsConfig' }],
              },
              component: () => import('@/views/app/administration/NumerationsConfig'),
            },
            {
              path: 'tefConfig',
              name: 'tefConfig',
              meta: {
                breadcrumb: [{ name: 'TefConfig' }, { name: 'tefConfig' }],
              },
              component: () => import('@/views/app/administration/components/tef/TefConfig'),
            },
            {
              path: 'tefConfigTerminales',
              name: 'tefConfigTerminales',
              meta: {
                breadcrumb: [{ name: 'TefConfigTerminales' }, { name: 'tefConfigTerminales' }],
              },
              component: () => import('@/views/app/administration/components/tef/TefConfigTerminales'),
            },
            {
              path: 'userConfig',
              name: 'userConfig',
              meta: {
                breadcrumb: [{ name: 'UserConfig' }, { name: 'userConfig' }],
              },
              component: () => import('@/views/app/administration/UserConfig'),
            },
            {
              path: 'confHiopos',
              name: 'confHiopos',
              meta: {
                breadcrumb: [{ name: 'ConfHiopos' }, { name: 'confHiopos' }],
              },
              component: () => import('@/views/app/administration/ConfHiopos'),
            },
            {
              path: 'RememberPassword',
              name: 'RememberPassword',
              meta: {
                breadcrumb: [{ name: 'RememberPassword' }, { name: 'RememberPassword' }],
              },
              component: () => import('@/views/app/sessions/RememberPassword'),
            },
            {
              path: 'Logs',
              name: 'Logs',
              meta: {
                breadcrumb: [{ name: 'Logs' }, { name: 'Logs' }],
              },
              component: () => import('@/views/app/administration/Logs'),
            },
          ],
        },
        {
          path: '/app/invoices',
          component: () => import('@/views/app/invoices/Index'),
          children: [
            {
              path: 'reportInvoices',
              name: 'reportInvoices',
              meta: {
                breadcrumb: [{ name: 'ReportInvoices' }, { name: 'reportInvoices' }],
              },
              component: () => import('@/views/app/invoices/ReportInvoices'),
            },
            {
              path: 'alertConfigs',
              name: 'alertConfigs',
              meta: {
                breadcrumb: [{ name: 'AlertConfigs' }, { name: 'alertConfigs' }],
              },
              component: () => import('@/views/app/invoices/AlertConfigs'),
            },
          ],
        },

      ],
    },

  ],
})

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    store.dispatch('changeThemeLoadingState', true)
  }
  next()
})

router.afterEach(() => {
  // Remove initial loading
  // const gullPreLoading = document.getElementById("loading_wrap");
  // if (gullPreLoading) {
  //   gullPreLoading.style.display = "none";
  // }
  // Complete the animation of the route progress bar.
  setTimeout(() => store.dispatch('changeThemeLoadingState', false), 500)
  // NProgress.done();
  // if (isMobile) {
  // if (window.innerWidth <= 1200) {
  //   // console.log("mobile");
  //   store.dispatch("changeSidebarProperties");
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
  //     store.dispatch("changeCompactSidebarProperties");
  //   }
  // } else {
  //   if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
  //     store.dispatch("changeSecondarySidebarProperties");
  //   }
  //   // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false;
  // }
})

export default router
