import axios from 'axios'
import { environment } from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    statusResponse: null,
    statusResponseMsg: "",
    updateStatusResponse: false,
    updateFabricanteResponse: false,
    updateEmailFEResponse: false,
    updateEmailDeliveryResponse: false,
    updateUserResponse: false,
    listClients: [],
  },
  mutations: {

    setListClients (state, data) {
      state.listClients = data
    },
    setResponseClient (state, data) {
      state.statusResponse = data
    },
    setResponseClientMsg (state, data) {
      state.statusResponseMsg = data
    },
    setUpdateResponseClient (state, data) {
      state.updateStatusResponse = data
    },
    setUpdateResponseFabricante (state, data) {
      state.updateFabricanteResponse = data
    },
    setUpdateResponseEmailFE (state, data) {
      state.updateEmailFEResponse = data
    },
    setUpdateResponseEmailDelivery (state, data) {
      state.updateEmailDeliveryResponse = data
    },
    setUpdateResponseUser (state, data) {
      state.updateUserResponse = data
    },
  },
  actions: {
    getListClients ({ commit }) {
      const endpoint = '/api/getClients'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListClients', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    insertClients ({ commit }, data) {
      const endpoint = '/api/insertClients'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      data.user = JSON.parse(atob(localStorage.getItem('uid'))).user

      const urlCertificateProd = "https://gestioncontribuyentesrest.thefactoryhka.com.co/api"
      const urlCertificateDemo = "https://demogestioncontribuyentesrest.thefactoryhka.com.co/api"

      if(data.Proveedor === "1") {
        if (data.Estado === "P") {
          data.UrlApiGestion = urlCertificateProd
        } else {
          data.UrlApiGestion = urlCertificateDemo
        }
      } else {
        data.UrlApiGestion = "URL_EDN"
      }

      axios
        .create()
        .post(url, data)
        .then(response => {
          if (response.data) {
              commit('setResponseClient', response.data.error)
              commit('setResponseClientMsg', response.data.Mensaje)
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    activeInactiveClient ({ commit }, data) {
      const endpoint = '/api/updateStatusClient'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      data.user = JSON.parse(atob(localStorage.getItem('uid'))).user

      axios
        .create()
        .post(url, data)
        .then(response => {
          if (response.data.Mensaje) {
            commit('setUpdateResponseClient', response.data.Mensaje)
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    updateFabricante ({ commit }, data) {
      const endpoint = '/api/updateFabricante'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      data = {'FabricanteSoftware':data.fabricante,'Nit':data.nit,'RazonSocialDistribuidor':data.razon_social,
         'Distribuidor':data.distribuidor, 'TokenEnterprise':data.token_enterprise, 'TokenPassword':data.token_password,
        'User':data.user }
      axios
          .create()
          .put(url, data)
          .then(response => {
            commit('setUpdateResponseFabricante', response.statusText == "OK")
          })
          .catch(function (error) {
            console.log(error.message)
          })
    },
    updateEmailFE ({ commit }, data) {
      const endpoint = '/api/updateEmailFE'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      data = {'Email':data.email,'TokenEnterprise':data.token_enterprise, 'TokenPassword':data.token_password,
        'User':data.user }
      axios
        .create()
        .put(url, data)
        .then(response => {
          commit('setUpdateResponseEmailFE', response.statusText == "OK")
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    updateEmailDelivery ({ commit }, data) {
      const endpoint = '/api/updateEmailDelivery'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      data = {'Email':data.email,'TokenEnterprise':data.token_enterprise, 'TokenPassword':data.token_password,
        'User':data.user }
      axios
        .create()
        .put(url, data)
        .then(response => {
          commit('setUpdateResponseEmailDelivery', response.statusText == "OK")
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    updateUser ({ commit }, data) {
      const endpoint = '/api/updateUser'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      data = {
        'NombreCompania':data.NombreCompania,
        'NitCliente':data.NitCliente,
        'ProveedorTecnologico':data.ProveedorTecnologico,
        'TokenEnterprise':data.TokenEnterprise,
        'TokenPassword':data.TokenPassword,
        'Url':data.Url,
        'UrlApiGestion':data.UrlApiGestion,
        'InformacionAdicional':data.InformacionAdicional,
        'Estado':data.Estado,
        'EmailDelivery':data.EmailDelivery,
        'EmailFE':data.EmailFE,
        'User':data.user,        
        'IdCompania':data.IdCompania,       
       } 
        
      axios
        .create()
        .put(url, data)
        .then(response => {
          commit('setUpdateResponseUser', response.statusText == "OK")
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    setStatusResponse ({ commit }, data) {
      commit('setResponseClient', data)
    },
    setStatusResponseMsg ({ commit }, data) {
      commit('setResponseClientMsg', data)
    },
    setUpdateResponseClient ({ commit }, data) {
      commit('setUpdateResponseClient', data)
    },
    setUpdateResponseFabricante ({ commit }, data) {
      commit('setUpdateResponseFabricante', data)
    },
    setUpdateResponseEmailFE ({ commit }, data) {
      commit('setUpdateResponseEmailFE', data)
    },
    setUpdateResponseEmailDelivery ({ commit }, data) {
      commit('setUpdateResponseEmailDelivery', data)
    },
    setUpdateResponseUser ({ commit }, data) {
      commit('setUpdateResponseUser', data)
    },
  },
}
