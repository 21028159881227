import axios from 'axios'
import { environment } from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    statusResponse: false,
    foliosData: [],
  },
  mutations: {
    setFoliosData(state, data) {
      state.foliosData = data
    },
    setResponseFolios(state, data) {
      state.statusResponse = data
    },
  },
  actions: {
    getFoliosData({commit }) {
      let params = 'IdCompania=' + JSON.parse(atob(localStorage.getItem('uid'))).idcompania

      const endpoint = '/api/getFoliosData?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params

      axios
        .create()
        .get(url)
        .then(response => {
          commit('setFoliosData', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    updateFoliosData({ commit }, data) {
      const endpoint = '/api/updateFoliosData'
      const url = environment.HIOPOSWEBSERVICES + endpoint

      data.IdCompania = JSON.parse(atob(localStorage.getItem('uid'))).idcompania
      data.Usuario = JSON.parse(atob(localStorage.getItem('uid'))).user

      axios
        .create()
        .put(url, data)
        .then(response => {
          commit('setResponseFolios', response.statusText=="OK")
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    setStatusResponse({ commit }, data) {
      commit('setResponseFolios', data)
    },
  },
}
