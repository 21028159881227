import axios from 'axios'
import { environment } from '@/data/environment/'
import {isArray} from "lodash";

export default {
  namespaced: true,
  state: {
    statusResponse: null,
    updateStatusResponse: null,
    statusResponseMsg: "",
    statusResponseUpdateMsg: "",
    listClients: [],
    listNumerations: [],
  },
  mutations: {
    setListClients (state, data) {
      state.listClients = data
    },
    setListNumerations (state, data) {
      if(isArray(data.Mensaje)) {
        state.listNumerations = data
      }
    },
    setResponseNumerations (state, data) {
      state.statusResponse = data
    },
    setResponseUpdateNumerations (state, data) {
      state.updateStatusResponse = data
    },
    setResponseNumerationsMsg (state, data) {
      state.statusResponseMsg = data
    },
    setResponseNumerationsUpdateMsg (state, data) {
      state.statusResponseUpdateMsg = data
    },
    resetListNumerations(state) {
      state.listNumerations = { Mensaje: [] };
    }
  },
  actions : {
    getListClients ({ commit }) {
      const endpoint = '/api/getClients'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListClients', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    getNumerations ({ commit }, data) {
      let params = 'IdCompania=' + data.IdCompania
      const endpoint = '/api/getNumerations?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params

      axios
        .create()
        .get(url, data)
        .then(response => {
            if(response.data) {
              commit('setListNumerations', response.data)
              commit('setResponseNumerations', response.data.error)
              if(response.data.error) {
                commit('resetListNumerations');
                commit('setResponseNumerationsMsg', response.data.Mensaje)
              } else {
                commit('setResponseNumerationsMsg', 'Numeraciones obtenidas correctamente')
              }
            }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    updateNumerations ({ commit }, data) {
      let params = 'IdCompania=' + data.IdCompania
      const endpoint = '/api/getNumerationsFromHKA?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      axios
        .create()
        .get(url, data)
        .then(response => {
          if(response.data) {
            commit('setResponseUpdateNumerations', response.data.error)
            if(typeof response.data.Mensaje === 'string') {
              commit('setResponseNumerationsUpdateMsg', response.data.Mensaje)
            } else {
              if(response.data.error) {
                let errorString = "";
                response.data.generalStatus.forEach(compania => {
                  if (compania.Codigo !== 200) {
                    errorString += ` || Empresa: ${compania.Compania}\n `;
                    errorString += ` Status: ${compania.Status}\n `;
                  }
                  if (compania.StatusBD !== null && compania.StatusBD !== "OK") {
                    errorString += ` StatusBD: ${compania.StatusBD}\n`;
                  }
                });
                commit('setResponseNumerationsUpdateMsg', errorString)
              } else {
                commit('setResponseNumerationsUpdateMsg', 'Numeraciones actualizadas correctamente')
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    setStatusResponse({ commit }, data) {
      commit('setResponseNumerations', data)
    },
    setStatusUpdateResponse({ commit }, data) {
      commit('setResponseUpdateNumerations', data)
    }
  }

}
