import axios from 'axios'
import {environment} from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    statusResponse: false,
    statusResponseMsg: '',
    updateStatusResponse: false,
    updateStatusTimeOutResponse: false,
    listEmisores: [],
    listDepartaments: [],
    listCitys: [],
    listClients: [],
  },
  mutations: {

    setListEmisores(state, data) {
      state.listEmisores = data
    },
    setListDepartaments(state, data) {
      state.listDepartaments = data
    },
    setListCitys(state, data) {
      state.listCitys = data
    },
    setListClients(state, data) {
      state.listClients = data
    },
    setResponseEmisor(state, data) {
      state.statusResponse = data
    },
    setResponseEmisorMsg(state, data) {
      state.statusResponseMsg = data
    },
    setUpdateResponseEmisor(state, data) {
      state.updateStatusResponse = data
    },
  },
  actions: {
    getListEmisor({commit}) {
      const endpoint = '/api/getEmisores'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListEmisores', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    getListClients({commit}) {
      const endpoint = '/api/getClientsEDN'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListClients', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    getListProvinces({commit}) {
      const endpoint = '/api/getDepartaments'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListDepartaments', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    getListCitys({commit}, data) {
      const params = `?departamento=${data.departamento}`

      const endpoint = '/api/getCitys'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListCitys', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    insertEmisor({commit}, data) {
      const endpoint = '/api/insertEmisor'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .post(url, data)
        .then(response => {
          if (response.data) {
            commit('setResponseEmisor', response.data.error)
            commit('setResponseEmisorMsg', response.data.Mensaje)
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    updateEmisor({commit}, data) {
      const endpoint = '/api/updateEmisor'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .put(url, data)
        .then(response => {
          console.log(response)
          if (response.data.error === false) {
            commit('setUpdateResponseEmisor', true)
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    setResponseEmisor ({ commit }, data) {
      commit('setResponseEmisor', data)
    },
    setUpdateResponseEmisor({ commit }, data) {
      commit('setUpdateResponseEmisor', data)
    },
  },
}
