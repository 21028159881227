import axios from 'axios'
import { environment } from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    statusResponseUser: false,
    listUsers: [],
    responsePassword: null,

  },
  mutations: {

    setListUsers (state, data) {
      state.listUsers = data
    },
    setResponseUser (state, data) {
      state.statusResponseUser = data
    },
    setResponsePassword (state, data) {
      state.responsePassword = data
      state.error = null
    },

  },
  actions: {
    getListUsers ({ commit }) {
      const endpoint = '/api/getUsers'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListUsers', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    insertUser ({ commit }, data) {
      const endpoint = '/api/insertUsers'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .post(url, data)
        .then(response => {
          if (response.data.Mensaje) {
              commit('setResponseUser', response.data.Mensaje)
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    setStatusResponse ({ commit }, data) {
      commit('setResponseUser', data)
    },
    sendPassword ({ commit }, data) {

      const endpoint = '/api/updatePassword?'
      const url = environment.HIOPOSWEBSERVICES + endpoint

      axios
        .create()
        .post(url, data)
        .then(response => {
          console.log(response)
          commit('setResponsePassword', response.data.Mensaje)
        })
        .catch(function (error) {
          commit('setResponsePassword', null)
          console.log(error.message)
        })
    },

    sendPasswordEmail ({ commit }, data) {

      const endpoint = '/api/updatePasswordEmail'
      const url = environment.HIOPOSWEBSERVICES + endpoint
    
      axios.post(url, data)
        .then(response => {
          commit('setResponsePassword', response.data)
        })
        .catch(function (error) {
          const errorMessage = error.response?.data?.Mensaje || 'Hubo un problema al actualizar la contraseña. Intente nuevamente.';
          commit('setResponsePassword', { error: true, Mensaje: errorMessage });
        });
    },

    setResponsePassword ({ commit }, data) {
      commit('setResponsePassword', data)
    },

  },
}
