export const vue2FiltersConfig = {
  capitalize: {
    onlyFirstLetter: false
  },
  number: {
    format: "0",
    thousandsSeparator: ".",
    decimalSeparator: ","
  },
  bytes: {
    decimalDigits: 2
  },
  percent: {
    decimalDigits: 2,
    multiplier: 100,
    decimalSeparator: "."
  },
  currency: {
    symbol: "$",
    decimalDigits: 2,
    thousandsSeparator: ".",
    decimalSeparator: ",",
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: false,
    showPlusSign: false
  },
  pluralize: {
    includeNumber: false
  },
  ordinal: {
    includeNumber: false
  }
};
