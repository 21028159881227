import axios from 'axios'
import { environment } from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    statusResponse: false,
    certificateData: [],
  },
  mutations: {
    setCertificateData(state, data) {
      state.certificateData = data
    },
    setResponseCertificate(state, data) {
      state.statusResponse = data
    }
  },
  actions: {
    getCertificateData({ commit }, data) {
      let params = 'IdCompania=' + JSON.parse(atob(localStorage.getItem('uid'))).idcompania

      const endpoint = '/api/getCertificateData?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params

      axios
        .create()
        .get(url)
        .then(response => {
          commit('setCertificateData', response.data)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    updateCertificateData({ commit }, data) {
      const endpoint = '/api/updateCertificateData'
      const url = environment.HIOPOSWEBSERVICES + endpoint

      data.Usuario = JSON.parse(atob(localStorage.getItem('uid'))).user
      data.IdCompania = JSON.parse(atob(localStorage.getItem('uid'))).idcompania

      axios
        .create()
        .put(url, data)
        .then(response => {
          commit('setResponseCertificate', response.statusText=="OK")
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    setStatusResponse({ commit }, data) {
      commit('setResponseCertificate', data)
    }
  }
}
