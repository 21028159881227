<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "HioposReports",
      // all titles will be injected into this template
      titleTemplate: "%s | Hiopos Reports Dashboard ",
    };
  },
  data() {
    return {
      inactivityTimeout: null,
      maxInactiveTime: localStorage.getItem("TimeInactive")
        ? parseInt(localStorage.getItem("TimeInactive"), 10) * 60 * 60 * 1000
        : 1 * 60 * 60 * 1000,
    };
  },
  methods: {
    startInactivityTimer() {
      this.inactivityTimeout = setTimeout(
        this.handleInactivity,
        this.maxInactiveTime
      );
    },
    resetTimer() {
      clearTimeout(this.inactivityTimeout);
      this.startInactivityTimer();
    },
    handleInactivity() {
      this.$root.$emit("logout");
    },
    getRouterParams() {
      // console.log(this.$router.currentRoute.query);
    },
  },
  mounted() {
    this.startInactivityTimer();

    window.addEventListener("keydown", this.resetTimer);
    window.addEventListener("click", this.resetTimer);
    window.removeEventListener("mousemove", this.resetTimer);

    const mq = window.matchMedia("(prefers-color-scheme: dark)");
    mq.addEventListener("change", (e) => {
      this.$vuetify.theme.dark = e.matches;
    });
    this.getRouterParams();
  },
  beforeDestroy() {
    clearTimeout(this.inactivityTimeout);
    window.removeEventListener("keydown", this.resetTimer);
    window.removeEventListener("click", this.resetTimer);
    window.removeEventListener("mousemove", this.resetTimer);
  },
};
</script>
