export default (to, from, next) => {
  if (
    localStorage.getItem('uid') != null &&
    localStorage.getItem('uid').length > 0
  ) {
    next()

  } else {
    localStorage.removeItem('uid')
    next('/app/sessions/sign-in')
  }
}
