import development from '@/data/environment/development'
import productivo from '@/data/environment/productivo'

let config = development

if (process.env.NODE_ENV === 'production') {
  config = productivo
}

export const environment = config
