import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins';
import octaviaKit from '@/plugins/octavia.kit';
import VueApexCharts from 'vue-apexcharts';
import './fake-db/index.js';
import VueClipboard from 'vue-clipboard2'
import CompositionApi from '@vue/composition-api'
import JsonViewer from 'vue-json-viewer'
import Vue2Filters from "vue2-filters";
import { vue2FiltersConfig } from "@/plugins/vue2FiltersConfig";

// Import JsonViewer as a Vue.js plugin
Vue.use(CompositionApi)
Vue.use(VueClipboard)
Vue.component('apexchart', VueApexCharts);
Vue.use(JsonViewer)
Vue.use(octaviaKit);
Vue.use(Vue2Filters, vue2FiltersConfig);
Vue.config.productionTip = false;
Vue.config.devtools = true

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
