import axios from 'axios'
import { environment } from '@/data/environment/'

export default {
  namespaced: true,
  state: {
    statusResponse: false,
    updateStatusResponse: false,
    updateStatusTimeOutResponse: false,
    listCredentials: [],
    listTerminales: [],
  },
  mutations: {

    setListCredentials (state, data) {
      state.listCredentials = data
    },
    setListTerminales (state, data) {
      state.listTerminales = data
    },
    setResponseCredentials (state, data) {
      state.statusResponse = data
    },
    setUpdateResponseCredentials (state, data) {
      state.updateStatusResponse = data
    },
    setUpdateResponseTimeOut (state, data) {
      state.updateStatusTimeOutResponse = data
    },
  },
  actions: {
    getListCredentials ({ commit }) {
      const endpoint = '/api/credentialsAll'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListCredentials', response.data.respuesta.Datos)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    updateCredentials ({ commit }, data) {
      const endpoint = '/api/credentials'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      data = {'token_key':data.token_key,'observation':data.observation,'code_store':data.code_store,'user':data.user,'dataCredentials':data.dataCredentials }     
       axios
        .create()
        .put(url, data)
        .then(response => {
          commit('setUpdateResponseCredentials', response.statusText == "OK")
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    updateCredentialsTimeOut ({ commit }, data) {
      const endpoint = '/api/credentialsTimeout'
      data = {'token_key':data.token_key,'observation':data.observation,'code_store':data.code_store,'user':data.user,'time_out_initial':data.time_out_initial,'time_out_sequential':data.time_out_sequential }	    
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .put(url, data)
        .then(response => {
          commit('setUpdateResponseTimeOut',  response.statusText == "OK")
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    getListTerminales ({ commit }, data) {
      let params = `&client=${data.Client}`
      params += `&type_tef=${data.Tef}`

      const endpoint = '/api/credentialsClient?'
      const url = environment.HIOPOSWEBSERVICES + endpoint + params
      axios
        .create()
        .get(url)
        .then(response => {
          commit('setListTerminales', response.data.respuesta.Datos)
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    insertCredentials ({ commit }, data) {
      const endpoint = '/api/credentials'
      const url = environment.HIOPOSWEBSERVICES + endpoint
      axios
        .create()
        .post(url, data)
        .then(response => {
          if (response.data) {
              commit('setResponseCredentials', response.data.respuesta)
          }
        })
        .catch(function (error) {
          console.log(error.message)
        })
    },
    setStatusResponse ({ commit }, data) {
      commit('setResponseCredentials', data)
    },
    setUpdateResponseCredentials ({ commit }, data) {
      commit('setUpdateResponseCredentials', data)
    },
	   setUpdateResponseTimeOut ({ commit }, data) {
      commit('setUpdateResponseTimeOut', data)
    },

  },
}
